export const albums_data = {
    "gore": {
        "name": "gore",
        "photos": [
            {
                "_thumb": "/static/_gallery/albums/gore/352x479_2015-12-22-095232_352x479_scrot.png",
                "faces": {},
                "height": 479,
                "name": "2015-12-22-095232_352x479_scrot.png",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/352x479_2015-12-22-095232_352x479_scrot.png",
                "srcSet": [
                    "/static/_gallery/albums/gore/352x479_2015-12-22-095232_352x479_scrot.png 352w",
                    "/static/_gallery/albums/gore/352x479_2015-12-22-095232_352x479_scrot.png 352w",
                    "/static/_gallery/albums/gore/352x479_2015-12-22-095232_352x479_scrot.png 352w",
                    "/static/_gallery/albums/gore/352x479_2015-12-22-095232_352x479_scrot.png 352w"
                ],
                "width": 352
            },
            {
                "_thumb": "/static/_gallery/albums/gore/499x273_2016-02-16-101505_1364x746_scrot.png",
                "faces": {},
                "height": 746,
                "name": "2016-02-16-101505_1364x746_scrot.png",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1364x746_2016-02-16-101505_1364x746_scrot.png",
                "srcSet": [
                    "/static/_gallery/albums/gore/499x273_2016-02-16-101505_1364x746_scrot.png 499w",
                    "/static/_gallery/albums/gore/800x437_2016-02-16-101505_1364x746_scrot.png 800w",
                    "/static/_gallery/albums/gore/1024x560_2016-02-16-101505_1364x746_scrot.png 1024w",
                    "/static/_gallery/albums/gore/1364x746_2016-02-16-101505_1364x746_scrot.png 1364w"
                ],
                "width": 1364
            },
            {
                "_thumb": "/static/_gallery/albums/gore/499x281_2016-06-07-212312_1366x768_scrot.png",
                "faces": {},
                "height": 768,
                "name": "2016-06-07-212312_1366x768_scrot.png",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1366x768_2016-06-07-212312_1366x768_scrot.png",
                "srcSet": [
                    "/static/_gallery/albums/gore/499x281_2016-06-07-212312_1366x768_scrot.png 499w",
                    "/static/_gallery/albums/gore/800x449_2016-06-07-212312_1366x768_scrot.png 800w",
                    "/static/_gallery/albums/gore/1024x575_2016-06-07-212312_1366x768_scrot.png 1024w",
                    "/static/_gallery/albums/gore/1366x768_2016-06-07-212312_1366x768_scrot.png 1366w"
                ],
                "width": 1366
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x276_2016-12-10-173650_2046x1130.png",
                "faces": {},
                "height": 1130,
                "name": "2016-12-10-173650_2046x1130.png",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1600x883_2016-12-10-173650_2046x1130.png",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x276_2016-12-10-173650_2046x1130.png 500w",
                    "/static/_gallery/albums/gore/800x441_2016-12-10-173650_2046x1130.png 800w",
                    "/static/_gallery/albums/gore/1024x565_2016-12-10-173650_2046x1130.png 1024w",
                    "/static/_gallery/albums/gore/1600x883_2016-12-10-173650_2046x1130.png 1600w"
                ],
                "width": 2046
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x172_2017-01-14-022205_3046x1050.png",
                "faces": {},
                "height": 1050,
                "name": "2017-01-14-022205_3046x1050.png",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1600x551_2017-01-14-022205_3046x1050.png",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x172_2017-01-14-022205_3046x1050.png 500w",
                    "/static/_gallery/albums/gore/800x275_2017-01-14-022205_3046x1050.png 800w",
                    "/static/_gallery/albums/gore/1024x352_2017-01-14-022205_3046x1050.png 1024w",
                    "/static/_gallery/albums/gore/1600x551_2017-01-14-022205_3046x1050.png 1600w"
                ],
                "width": 3046
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x306_2017-01-15-104238_1678x1028.png",
                "faces": {},
                "height": 1028,
                "name": "2017-01-15-104238_1678x1028.png",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1600x980_2017-01-15-104238_1678x1028.png",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x306_2017-01-15-104238_1678x1028.png 500w",
                    "/static/_gallery/albums/gore/800x490_2017-01-15-104238_1678x1028.png 800w",
                    "/static/_gallery/albums/gore/1024x627_2017-01-15-104238_1678x1028.png 1024w",
                    "/static/_gallery/albums/gore/1600x980_2017-01-15-104238_1678x1028.png 1600w"
                ],
                "width": 1678
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x286_2017-02-10-192133_1027x589.png",
                "faces": {},
                "height": 589,
                "name": "2017-02-10-192133_1027x589.png",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1027x589_2017-02-10-192133_1027x589.png",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x286_2017-02-10-192133_1027x589.png 500w",
                    "/static/_gallery/albums/gore/800x458_2017-02-10-192133_1027x589.png 800w",
                    "/static/_gallery/albums/gore/1024x587_2017-02-10-192133_1027x589.png 1024w",
                    "/static/_gallery/albums/gore/1027x589_2017-02-10-192133_1027x589.png 1027w"
                ],
                "width": 1027
            },
            {
                "_thumb": "/static/_gallery/albums/gore/369x101_2017-02-12-212522_369x101.png",
                "faces": {},
                "height": 101,
                "name": "2017-02-12-212522_369x101.png",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/369x101_2017-02-12-212522_369x101.png",
                "srcSet": [
                    "/static/_gallery/albums/gore/369x101_2017-02-12-212522_369x101.png 369w",
                    "/static/_gallery/albums/gore/369x101_2017-02-12-212522_369x101.png 369w",
                    "/static/_gallery/albums/gore/369x101_2017-02-12-212522_369x101.png 369w",
                    "/static/_gallery/albums/gore/369x101_2017-02-12-212522_369x101.png 369w"
                ],
                "width": 369
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x95_2017-02-12-225504_1349x257.png",
                "faces": {},
                "height": 257,
                "name": "2017-02-12-225504_1349x257.png",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1349x257_2017-02-12-225504_1349x257.png",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x95_2017-02-12-225504_1349x257.png 500w",
                    "/static/_gallery/albums/gore/800x152_2017-02-12-225504_1349x257.png 800w",
                    "/static/_gallery/albums/gore/1024x195_2017-02-12-225504_1349x257.png 1024w",
                    "/static/_gallery/albums/gore/1349x257_2017-02-12-225504_1349x257.png 1349w"
                ],
                "width": 1349
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x145_2017-03-12-200226_3968x1152.png",
                "faces": {},
                "height": 1152,
                "name": "2017-03-12-200226_3968x1152.png",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1600x464_2017-03-12-200226_3968x1152.png",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x145_2017-03-12-200226_3968x1152.png 500w",
                    "/static/_gallery/albums/gore/800x232_2017-03-12-200226_3968x1152.png 800w",
                    "/static/_gallery/albums/gore/1024x297_2017-03-12-200226_3968x1152.png 1024w",
                    "/static/_gallery/albums/gore/1600x464_2017-03-12-200226_3968x1152.png 1600w"
                ],
                "width": 3968
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x276_2017-04-02-165305_2046x1130.png",
                "faces": {},
                "height": 1130,
                "name": "2017-04-02-165305_2046x1130.png",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1600x883_2017-04-02-165305_2046x1130.png",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x276_2017-04-02-165305_2046x1130.png 500w",
                    "/static/_gallery/albums/gore/800x441_2017-04-02-165305_2046x1130.png 800w",
                    "/static/_gallery/albums/gore/1024x565_2017-04-02-165305_2046x1130.png 1024w",
                    "/static/_gallery/albums/gore/1600x883_2017-04-02-165305_2046x1130.png 1600w"
                ],
                "width": 2046
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x144_2017-05-01-155234_829x239.png",
                "faces": {},
                "height": 239,
                "name": "2017-05-01-155234_829x239.png",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/829x239_2017-05-01-155234_829x239.png",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x144_2017-05-01-155234_829x239.png 500w",
                    "/static/_gallery/albums/gore/800x230_2017-05-01-155234_829x239.png 800w",
                    "/static/_gallery/albums/gore/829x239_2017-05-01-155234_829x239.png 829w",
                    "/static/_gallery/albums/gore/829x239_2017-05-01-155234_829x239.png 829w"
                ],
                "width": 829
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x276_2017-05-03-162510_2046x1130.png",
                "faces": {},
                "height": 1130,
                "name": "2017-05-03-162510_2046x1130.png",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1600x883_2017-05-03-162510_2046x1130.png",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x276_2017-05-03-162510_2046x1130.png 500w",
                    "/static/_gallery/albums/gore/800x441_2017-05-03-162510_2046x1130.png 800w",
                    "/static/_gallery/albums/gore/1024x565_2017-05-03-162510_2046x1130.png 1024w",
                    "/static/_gallery/albums/gore/1600x883_2017-05-03-162510_2046x1130.png 1600w"
                ],
                "width": 2046
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x275_2017-06-28-102829_2046x1129.png",
                "faces": {},
                "height": 1129,
                "name": "2017-06-28-102829_2046x1129.png",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1600x882_2017-06-28-102829_2046x1129.png",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x275_2017-06-28-102829_2046x1129.png 500w",
                    "/static/_gallery/albums/gore/800x441_2017-06-28-102829_2046x1129.png 800w",
                    "/static/_gallery/albums/gore/1024x565_2017-06-28-102829_2046x1129.png 1024w",
                    "/static/_gallery/albums/gore/1600x882_2017-06-28-102829_2046x1129.png 1600w"
                ],
                "width": 2046
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x275_2017-07-29-054024_1918x1057.png",
                "faces": {},
                "height": 1057,
                "name": "2017-07-29-054024_1918x1057.png",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1600x881_2017-07-29-054024_1918x1057.png",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x275_2017-07-29-054024_1918x1057.png 500w",
                    "/static/_gallery/albums/gore/800x440_2017-07-29-054024_1918x1057.png 800w",
                    "/static/_gallery/albums/gore/1024x564_2017-07-29-054024_1918x1057.png 1024w",
                    "/static/_gallery/albums/gore/1600x881_2017-07-29-054024_1918x1057.png 1600w"
                ],
                "width": 1918
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x275_2017-09-30-210254_2046x1129.png",
                "faces": {},
                "height": 1129,
                "name": "2017-09-30-210254_2046x1129.png",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1600x882_2017-09-30-210254_2046x1129.png",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x275_2017-09-30-210254_2046x1129.png 500w",
                    "/static/_gallery/albums/gore/800x441_2017-09-30-210254_2046x1129.png 800w",
                    "/static/_gallery/albums/gore/1024x565_2017-09-30-210254_2046x1129.png 1024w",
                    "/static/_gallery/albums/gore/1600x882_2017-09-30-210254_2046x1129.png 1600w"
                ],
                "width": 2046
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x62_2017-10-01-182245_1234x155.png",
                "faces": {},
                "height": 155,
                "name": "2017-10-01-182245_1234x155.png",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1234x155_2017-10-01-182245_1234x155.png",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x62_2017-10-01-182245_1234x155.png 500w",
                    "/static/_gallery/albums/gore/800x100_2017-10-01-182245_1234x155.png 800w",
                    "/static/_gallery/albums/gore/1024x128_2017-10-01-182245_1234x155.png 1024w",
                    "/static/_gallery/albums/gore/1234x155_2017-10-01-182245_1234x155.png 1234w"
                ],
                "width": 1234
            },
            {
                "_thumb": "/static/_gallery/albums/gore/499x273_2017-11-16-035316_1364x746.png",
                "faces": {},
                "height": 746,
                "name": "2017-11-16-035316_1364x746.png",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1364x746_2017-11-16-035316_1364x746.png",
                "srcSet": [
                    "/static/_gallery/albums/gore/499x273_2017-11-16-035316_1364x746.png 499w",
                    "/static/_gallery/albums/gore/800x437_2017-11-16-035316_1364x746.png 800w",
                    "/static/_gallery/albums/gore/1024x560_2017-11-16-035316_1364x746.png 1024w",
                    "/static/_gallery/albums/gore/1364x746_2017-11-16-035316_1364x746.png 1364w"
                ],
                "width": 1364
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x275_2017-11-30-223637_2046x1129.png",
                "faces": {},
                "height": 1129,
                "name": "2017-11-30-223637_2046x1129.png",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1600x882_2017-11-30-223637_2046x1129.png",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x275_2017-11-30-223637_2046x1129.png 500w",
                    "/static/_gallery/albums/gore/800x441_2017-11-30-223637_2046x1129.png 800w",
                    "/static/_gallery/albums/gore/1024x565_2017-11-30-223637_2046x1129.png 1024w",
                    "/static/_gallery/albums/gore/1600x882_2017-11-30-223637_2046x1129.png 1600w"
                ],
                "width": 2046
            },
            {
                "_thumb": "/static/_gallery/albums/gore/499x273_2018-05-08-212327_1364x745.png",
                "faces": {},
                "height": 745,
                "name": "2018-05-08-212327_1364x745.png",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1364x745_2018-05-08-212327_1364x745.png",
                "srcSet": [
                    "/static/_gallery/albums/gore/499x273_2018-05-08-212327_1364x745.png 499w",
                    "/static/_gallery/albums/gore/800x436_2018-05-08-212327_1364x745.png 800w",
                    "/static/_gallery/albums/gore/1024x559_2018-05-08-212327_1364x745.png 1024w",
                    "/static/_gallery/albums/gore/1364x745_2018-05-08-212327_1364x745.png 1364w"
                ],
                "width": 1364
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x275_2018-05-29-005219_2046x1129.png",
                "faces": {},
                "height": 1129,
                "name": "2018-05-29-005219_2046x1129.png",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1600x882_2018-05-29-005219_2046x1129.png",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x275_2018-05-29-005219_2046x1129.png 500w",
                    "/static/_gallery/albums/gore/800x441_2018-05-29-005219_2046x1129.png 800w",
                    "/static/_gallery/albums/gore/1024x565_2018-05-29-005219_2046x1129.png 1024w",
                    "/static/_gallery/albums/gore/1600x882_2018-05-29-005219_2046x1129.png 1600w"
                ],
                "width": 2046
            },
            {
                "_thumb": "/static/_gallery/albums/gore/499x281_2018-07-12-153008_1366x768.png",
                "faces": {},
                "height": 768,
                "name": "2018-07-12-153008_1366x768.png",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1366x768_2018-07-12-153008_1366x768.png",
                "srcSet": [
                    "/static/_gallery/albums/gore/499x281_2018-07-12-153008_1366x768.png 499w",
                    "/static/_gallery/albums/gore/800x449_2018-07-12-153008_1366x768.png 800w",
                    "/static/_gallery/albums/gore/1024x575_2018-07-12-153008_1366x768.png 1024w",
                    "/static/_gallery/albums/gore/1366x768_2018-07-12-153008_1366x768.png 1366w"
                ],
                "width": 1366
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x281_2020-04-12-weird-unison.png",
                "faces": {},
                "height": 1080,
                "name": "2020-04-12-weird-unison.png",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1600x900_2020-04-12-weird-unison.png",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x281_2020-04-12-weird-unison.png 500w",
                    "/static/_gallery/albums/gore/800x450_2020-04-12-weird-unison.png 800w",
                    "/static/_gallery/albums/gore/1024x576_2020-04-12-weird-unison.png 1024w",
                    "/static/_gallery/albums/gore/1600x900_2020-04-12-weird-unison.png 1600w"
                ],
                "width": 1920
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x332_2021-05-01T13:38:41:-05:00_867x576.png",
                "faces": {},
                "height": 576,
                "name": "2021-05-01T13:38:41:-05:00_867x576.png",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/867x576_2021-05-01T13:38:41:-05:00_867x576.png",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x332_2021-05-01T13:38:41:-05:00_867x576.png 500w",
                    "/static/_gallery/albums/gore/800x531_2021-05-01T13:38:41:-05:00_867x576.png 800w",
                    "/static/_gallery/albums/gore/867x576_2021-05-01T13:38:41:-05:00_867x576.png 867w",
                    "/static/_gallery/albums/gore/867x576_2021-05-01T13:38:41:-05:00_867x576.png 867w"
                ],
                "width": 867
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x887_IMG_0475.PNG",
                "faces": {},
                "height": 1136,
                "name": "IMG_0475.PNG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/640x1136_IMG_0475.PNG",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x887_IMG_0475.PNG 500w",
                    "/static/_gallery/albums/gore/640x1136_IMG_0475.PNG 640w",
                    "/static/_gallery/albums/gore/640x1136_IMG_0475.PNG 640w",
                    "/static/_gallery/albums/gore/640x1136_IMG_0475.PNG 640w"
                ],
                "width": 640
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x375_IMG_0642.JPG",
                "faces": {},
                "height": 2448,
                "name": "IMG_0642.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1600x1200_IMG_0642.JPG",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x375_IMG_0642.JPG 500w",
                    "/static/_gallery/albums/gore/800x600_IMG_0642.JPG 800w",
                    "/static/_gallery/albums/gore/1024x768_IMG_0642.JPG 1024w",
                    "/static/_gallery/albums/gore/1600x1200_IMG_0642.JPG 1600w"
                ],
                "width": 3264
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x887_IMG_0650.PNG",
                "faces": {},
                "height": 1136,
                "name": "IMG_0650.PNG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/640x1136_IMG_0650.PNG",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x887_IMG_0650.PNG 500w",
                    "/static/_gallery/albums/gore/640x1136_IMG_0650.PNG 640w",
                    "/static/_gallery/albums/gore/640x1136_IMG_0650.PNG 640w",
                    "/static/_gallery/albums/gore/640x1136_IMG_0650.PNG 640w"
                ],
                "width": 640
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x375_IMG_0652.JPG",
                "faces": {},
                "height": 2448,
                "name": "IMG_0652.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1600x1200_IMG_0652.JPG",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x375_IMG_0652.JPG 500w",
                    "/static/_gallery/albums/gore/800x600_IMG_0652.JPG 800w",
                    "/static/_gallery/albums/gore/1024x768_IMG_0652.JPG 1024w",
                    "/static/_gallery/albums/gore/1600x1200_IMG_0652.JPG 1600w"
                ],
                "width": 3264
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x375_IMG_0729.JPG",
                "faces": {},
                "height": 2448,
                "name": "IMG_0729.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1600x1200_IMG_0729.JPG",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x375_IMG_0729.JPG 500w",
                    "/static/_gallery/albums/gore/800x600_IMG_0729.JPG 800w",
                    "/static/_gallery/albums/gore/1024x768_IMG_0729.JPG 1024w",
                    "/static/_gallery/albums/gore/1600x1200_IMG_0729.JPG 1600w"
                ],
                "width": 3264
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x375_IMG_0776.JPG",
                "faces": {},
                "height": 2448,
                "name": "IMG_0776.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1600x1200_IMG_0776.JPG",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x375_IMG_0776.JPG 500w",
                    "/static/_gallery/albums/gore/800x600_IMG_0776.JPG 800w",
                    "/static/_gallery/albums/gore/1024x768_IMG_0776.JPG 1024w",
                    "/static/_gallery/albums/gore/1600x1200_IMG_0776.JPG 1600w"
                ],
                "width": 3264
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x887_IMG_0787.PNG",
                "faces": {},
                "height": 1136,
                "name": "IMG_0787.PNG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/640x1136_IMG_0787.PNG",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x887_IMG_0787.PNG 500w",
                    "/static/_gallery/albums/gore/640x1136_IMG_0787.PNG 640w",
                    "/static/_gallery/albums/gore/640x1136_IMG_0787.PNG 640w",
                    "/static/_gallery/albums/gore/640x1136_IMG_0787.PNG 640w"
                ],
                "width": 640
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x281_IMG_0816.PNG",
                "faces": {},
                "height": 640,
                "name": "IMG_0816.PNG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1136x640_IMG_0816.PNG",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x281_IMG_0816.PNG 500w",
                    "/static/_gallery/albums/gore/800x450_IMG_0816.PNG 800w",
                    "/static/_gallery/albums/gore/1024x576_IMG_0816.PNG 1024w",
                    "/static/_gallery/albums/gore/1136x640_IMG_0816.PNG 1136w"
                ],
                "width": 1136
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x888_IMG_0850.PNG",
                "faces": {},
                "height": 2208,
                "name": "IMG_0850.PNG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1242x2208_IMG_0850.PNG",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x888_IMG_0850.PNG 500w",
                    "/static/_gallery/albums/gore/800x1422_IMG_0850.PNG 800w",
                    "/static/_gallery/albums/gore/1024x1820_IMG_0850.PNG 1024w",
                    "/static/_gallery/albums/gore/1242x2208_IMG_0850.PNG 1242w"
                ],
                "width": 1242
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x888_IMG_0851.PNG",
                "faces": {},
                "height": 2208,
                "name": "IMG_0851.PNG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1242x2208_IMG_0851.PNG",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x888_IMG_0851.PNG 500w",
                    "/static/_gallery/albums/gore/800x1422_IMG_0851.PNG 800w",
                    "/static/_gallery/albums/gore/1024x1820_IMG_0851.PNG 1024w",
                    "/static/_gallery/albums/gore/1242x2208_IMG_0851.PNG 1242w"
                ],
                "width": 1242
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x375_IMG_0875-1.JPG",
                "faces": {},
                "height": 3024,
                "name": "IMG_0875-1.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1600x1200_IMG_0875-1.JPG",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x375_IMG_0875-1.JPG 500w",
                    "/static/_gallery/albums/gore/800x600_IMG_0875-1.JPG 800w",
                    "/static/_gallery/albums/gore/1024x768_IMG_0875-1.JPG 1024w",
                    "/static/_gallery/albums/gore/1600x1200_IMG_0875-1.JPG 1600w"
                ],
                "width": 4032
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x375_IMG_0880.JPG",
                "faces": {},
                "height": 2448,
                "name": "IMG_0880.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1600x1200_IMG_0880.JPG",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x375_IMG_0880.JPG 500w",
                    "/static/_gallery/albums/gore/800x600_IMG_0880.JPG 800w",
                    "/static/_gallery/albums/gore/1024x768_IMG_0880.JPG 1024w",
                    "/static/_gallery/albums/gore/1600x1200_IMG_0880.JPG 1600w"
                ],
                "width": 3264
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x375_IMG_0881.JPG",
                "faces": {},
                "height": 2448,
                "name": "IMG_0881.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1600x1200_IMG_0881.JPG",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x375_IMG_0881.JPG 500w",
                    "/static/_gallery/albums/gore/800x600_IMG_0881.JPG 800w",
                    "/static/_gallery/albums/gore/1024x768_IMG_0881.JPG 1024w",
                    "/static/_gallery/albums/gore/1600x1200_IMG_0881.JPG 1600w"
                ],
                "width": 3264
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x375_IMG_0882.JPG",
                "faces": {},
                "height": 2448,
                "name": "IMG_0882.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1600x1200_IMG_0882.JPG",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x375_IMG_0882.JPG 500w",
                    "/static/_gallery/albums/gore/800x600_IMG_0882.JPG 800w",
                    "/static/_gallery/albums/gore/1024x768_IMG_0882.JPG 1024w",
                    "/static/_gallery/albums/gore/1600x1200_IMG_0882.JPG 1600w"
                ],
                "width": 3264
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x375_IMG_0883.JPG",
                "faces": {},
                "height": 2448,
                "name": "IMG_0883.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1600x1200_IMG_0883.JPG",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x375_IMG_0883.JPG 500w",
                    "/static/_gallery/albums/gore/800x600_IMG_0883.JPG 800w",
                    "/static/_gallery/albums/gore/1024x768_IMG_0883.JPG 1024w",
                    "/static/_gallery/albums/gore/1600x1200_IMG_0883.JPG 1600w"
                ],
                "width": 3264
            },
            {
                "_thumb": "/static/_gallery/albums/gore/499x669_IMG_0924.JPG",
                "faces": {},
                "height": 3640,
                "name": "IMG_0924.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1600x2141_IMG_0924.JPG",
                "srcSet": [
                    "/static/_gallery/albums/gore/499x669_IMG_0924.JPG 499w",
                    "/static/_gallery/albums/gore/800x1070_IMG_0924.JPG 800w",
                    "/static/_gallery/albums/gore/1024x1370_IMG_0924.JPG 1024w",
                    "/static/_gallery/albums/gore/1600x2141_IMG_0924.JPG 1600w"
                ],
                "width": 2720
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x888_IMG_0994.PNG",
                "faces": {},
                "height": 2208,
                "name": "IMG_0994.PNG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1242x2208_IMG_0994.PNG",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x888_IMG_0994.PNG 500w",
                    "/static/_gallery/albums/gore/800x1422_IMG_0994.PNG 800w",
                    "/static/_gallery/albums/gore/1024x1820_IMG_0994.PNG 1024w",
                    "/static/_gallery/albums/gore/1242x2208_IMG_0994.PNG 1242w"
                ],
                "width": 1242
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x375_IMG_1020.JPG",
                "faces": {},
                "height": 2448,
                "name": "IMG_1020.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1600x1200_IMG_1020.JPG",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x375_IMG_1020.JPG 500w",
                    "/static/_gallery/albums/gore/800x600_IMG_1020.JPG 800w",
                    "/static/_gallery/albums/gore/1024x768_IMG_1020.JPG 1024w",
                    "/static/_gallery/albums/gore/1600x1200_IMG_1020.JPG 1600w"
                ],
                "width": 3264
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x375_IMG_1023.JPG",
                "faces": {},
                "height": 2448,
                "name": "IMG_1023.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1600x1200_IMG_1023.JPG",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x375_IMG_1023.JPG 500w",
                    "/static/_gallery/albums/gore/800x600_IMG_1023.JPG 800w",
                    "/static/_gallery/albums/gore/1024x768_IMG_1023.JPG 1024w",
                    "/static/_gallery/albums/gore/1600x1200_IMG_1023.JPG 1600w"
                ],
                "width": 3264
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x375_IMG_1024.JPG",
                "faces": {},
                "height": 2448,
                "name": "IMG_1024.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1600x1200_IMG_1024.JPG",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x375_IMG_1024.JPG 500w",
                    "/static/_gallery/albums/gore/800x600_IMG_1024.JPG 800w",
                    "/static/_gallery/albums/gore/1024x768_IMG_1024.JPG 1024w",
                    "/static/_gallery/albums/gore/1600x1200_IMG_1024.JPG 1600w"
                ],
                "width": 3264
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x375_IMG_1089.JPG",
                "faces": {},
                "height": 2448,
                "name": "IMG_1089.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1600x1200_IMG_1089.JPG",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x375_IMG_1089.JPG 500w",
                    "/static/_gallery/albums/gore/800x600_IMG_1089.JPG 800w",
                    "/static/_gallery/albums/gore/1024x768_IMG_1089.JPG 1024w",
                    "/static/_gallery/albums/gore/1600x1200_IMG_1089.JPG 1600w"
                ],
                "width": 3264
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x888_IMG_1174.PNG",
                "faces": {},
                "height": 2208,
                "name": "IMG_1174.PNG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1242x2208_IMG_1174.PNG",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x888_IMG_1174.PNG 500w",
                    "/static/_gallery/albums/gore/800x1422_IMG_1174.PNG 800w",
                    "/static/_gallery/albums/gore/1024x1820_IMG_1174.PNG 1024w",
                    "/static/_gallery/albums/gore/1242x2208_IMG_1174.PNG 1242w"
                ],
                "width": 1242
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x375_IMG_1254.JPG",
                "faces": {},
                "height": 2448,
                "name": "IMG_1254.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1600x1200_IMG_1254.JPG",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x375_IMG_1254.JPG 500w",
                    "/static/_gallery/albums/gore/800x600_IMG_1254.JPG 800w",
                    "/static/_gallery/albums/gore/1024x768_IMG_1254.JPG 1024w",
                    "/static/_gallery/albums/gore/1600x1200_IMG_1254.JPG 1600w"
                ],
                "width": 3264
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x666_IMG_20200427_055002.jpg",
                "faces": {},
                "height": 4160,
                "name": "IMG_20200427_055002.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1600x2133_IMG_20200427_055002.jpg",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x666_IMG_20200427_055002.jpg 500w",
                    "/static/_gallery/albums/gore/800x1066_IMG_20200427_055002.jpg 800w",
                    "/static/_gallery/albums/gore/1024x1365_IMG_20200427_055002.jpg 1024w",
                    "/static/_gallery/albums/gore/1600x2133_IMG_20200427_055002.jpg 1600w"
                ],
                "width": 3120
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x666_IMG_20200516_160502.jpg",
                "faces": {},
                "height": 4160,
                "name": "IMG_20200516_160502.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1600x2133_IMG_20200516_160502.jpg",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x666_IMG_20200516_160502.jpg 500w",
                    "/static/_gallery/albums/gore/800x1066_IMG_20200516_160502.jpg 800w",
                    "/static/_gallery/albums/gore/1024x1365_IMG_20200516_160502.jpg 1024w",
                    "/static/_gallery/albums/gore/1600x2133_IMG_20200516_160502.jpg 1600w"
                ],
                "width": 3120
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x375_IMG_20200704_025551799.jpg",
                "faces": {},
                "height": 3120,
                "name": "IMG_20200704_025551799.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1600x1200_IMG_20200704_025551799.jpg",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x375_IMG_20200704_025551799.jpg 500w",
                    "/static/_gallery/albums/gore/800x600_IMG_20200704_025551799.jpg 800w",
                    "/static/_gallery/albums/gore/1024x768_IMG_20200704_025551799.jpg 1024w",
                    "/static/_gallery/albums/gore/1600x1200_IMG_20200704_025551799.jpg 1600w"
                ],
                "width": 4160
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x666_IMG_20200817_164537296.jpg",
                "faces": {},
                "height": 4160,
                "name": "IMG_20200817_164537296.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1600x2133_IMG_20200817_164537296.jpg",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x666_IMG_20200817_164537296.jpg 500w",
                    "/static/_gallery/albums/gore/800x1066_IMG_20200817_164537296.jpg 800w",
                    "/static/_gallery/albums/gore/1024x1365_IMG_20200817_164537296.jpg 1024w",
                    "/static/_gallery/albums/gore/1600x2133_IMG_20200817_164537296.jpg 1600w"
                ],
                "width": 3120
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x666_IMG_20200821_211642426.jpg",
                "faces": {},
                "height": 4160,
                "name": "IMG_20200821_211642426.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1600x2133_IMG_20200821_211642426.jpg",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x666_IMG_20200821_211642426.jpg 500w",
                    "/static/_gallery/albums/gore/800x1066_IMG_20200821_211642426.jpg 800w",
                    "/static/_gallery/albums/gore/1024x1365_IMG_20200821_211642426.jpg 1024w",
                    "/static/_gallery/albums/gore/1600x2133_IMG_20200821_211642426.jpg 1600w"
                ],
                "width": 3120
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x666_IMG_20201023_211500922.jpg",
                "faces": {},
                "height": 4160,
                "name": "IMG_20201023_211500922.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1600x2133_IMG_20201023_211500922.jpg",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x666_IMG_20201023_211500922.jpg 500w",
                    "/static/_gallery/albums/gore/800x1066_IMG_20201023_211500922.jpg 800w",
                    "/static/_gallery/albums/gore/1024x1365_IMG_20201023_211500922.jpg 1024w",
                    "/static/_gallery/albums/gore/1600x2133_IMG_20201023_211500922.jpg 1600w"
                ],
                "width": 3120
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x666_IMG_20201125_191313868.jpg",
                "faces": {},
                "height": 4160,
                "name": "IMG_20201125_191313868.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1600x2133_IMG_20201125_191313868.jpg",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x666_IMG_20201125_191313868.jpg 500w",
                    "/static/_gallery/albums/gore/800x1066_IMG_20201125_191313868.jpg 800w",
                    "/static/_gallery/albums/gore/1024x1365_IMG_20201125_191313868.jpg 1024w",
                    "/static/_gallery/albums/gore/1600x2133_IMG_20201125_191313868.jpg 1600w"
                ],
                "width": 3120
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x666_IMG_20210117_003841350.jpg",
                "faces": {},
                "height": 4160,
                "name": "IMG_20210117_003841350.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1600x2133_IMG_20210117_003841350.jpg",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x666_IMG_20210117_003841350.jpg 500w",
                    "/static/_gallery/albums/gore/800x1066_IMG_20210117_003841350.jpg 800w",
                    "/static/_gallery/albums/gore/1024x1365_IMG_20210117_003841350.jpg 1024w",
                    "/static/_gallery/albums/gore/1600x2133_IMG_20210117_003841350.jpg 1600w"
                ],
                "width": 3120
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x666_IMG_20210117_204135503.jpg",
                "faces": {},
                "height": 4160,
                "name": "IMG_20210117_204135503.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1600x2133_IMG_20210117_204135503.jpg",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x666_IMG_20210117_204135503.jpg 500w",
                    "/static/_gallery/albums/gore/800x1066_IMG_20210117_204135503.jpg 800w",
                    "/static/_gallery/albums/gore/1024x1365_IMG_20210117_204135503.jpg 1024w",
                    "/static/_gallery/albums/gore/1600x2133_IMG_20210117_204135503.jpg 1600w"
                ],
                "width": 3120
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x235_Screenshot from 2022-09-18 00-46-25.png",
                "faces": {},
                "height": 450,
                "name": "Screenshot from 2022-09-18 00-46-25.png",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/955x450_Screenshot from 2022-09-18 00-46-25.png",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x235_Screenshot from 2022-09-18 00-46-25.png 500w",
                    "/static/_gallery/albums/gore/799x376_Screenshot from 2022-09-18 00-46-25.png 799w",
                    "/static/_gallery/albums/gore/955x450_Screenshot from 2022-09-18 00-46-25.png 955w",
                    "/static/_gallery/albums/gore/955x450_Screenshot from 2022-09-18 00-46-25.png 955w"
                ],
                "width": 955
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x1050_Screenshot_20200511-011808.png",
                "faces": {},
                "height": 1512,
                "name": "Screenshot_20200511-011808.png",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/720x1512_Screenshot_20200511-011808.png",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x1050_Screenshot_20200511-011808.png 500w",
                    "/static/_gallery/albums/gore/720x1512_Screenshot_20200511-011808.png 720w",
                    "/static/_gallery/albums/gore/720x1512_Screenshot_20200511-011808.png 720w",
                    "/static/_gallery/albums/gore/720x1512_Screenshot_20200511-011808.png 720w"
                ],
                "width": 720
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x1050_Screenshot_20200731-013014.png",
                "faces": {},
                "height": 1512,
                "name": "Screenshot_20200731-013014.png",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/720x1512_Screenshot_20200731-013014.png",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x1050_Screenshot_20200731-013014.png 500w",
                    "/static/_gallery/albums/gore/720x1512_Screenshot_20200731-013014.png 720w",
                    "/static/_gallery/albums/gore/720x1512_Screenshot_20200731-013014.png 720w",
                    "/static/_gallery/albums/gore/720x1512_Screenshot_20200731-013014.png 720w"
                ],
                "width": 720
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x1050_Screenshot_20201012-133827.png",
                "faces": {},
                "height": 1512,
                "name": "Screenshot_20201012-133827.png",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/720x1512_Screenshot_20201012-133827.png",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x1050_Screenshot_20201012-133827.png 500w",
                    "/static/_gallery/albums/gore/720x1512_Screenshot_20201012-133827.png 720w",
                    "/static/_gallery/albums/gore/720x1512_Screenshot_20201012-133827.png 720w",
                    "/static/_gallery/albums/gore/720x1512_Screenshot_20201012-133827.png 720w"
                ],
                "width": 720
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x1050_Screenshot_20210223-233256.png",
                "faces": {},
                "height": 1512,
                "name": "Screenshot_20210223-233256.png",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/720x1512_Screenshot_20210223-233256.png",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x1050_Screenshot_20210223-233256.png 500w",
                    "/static/_gallery/albums/gore/720x1512_Screenshot_20210223-233256.png 720w",
                    "/static/_gallery/albums/gore/720x1512_Screenshot_20210223-233256.png 720w",
                    "/static/_gallery/albums/gore/720x1512_Screenshot_20210223-233256.png 720w"
                ],
                "width": 720
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x1050_Screenshot_20210224-231105.png",
                "faces": {},
                "height": 1512,
                "name": "Screenshot_20210224-231105.png",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/720x1512_Screenshot_20210224-231105.png",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x1050_Screenshot_20210224-231105.png 500w",
                    "/static/_gallery/albums/gore/720x1512_Screenshot_20210224-231105.png 720w",
                    "/static/_gallery/albums/gore/720x1512_Screenshot_20210224-231105.png 720w",
                    "/static/_gallery/albums/gore/720x1512_Screenshot_20210224-231105.png 720w"
                ],
                "width": 720
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x1050_Screenshot_20210403-175831.png",
                "faces": {},
                "height": 1512,
                "name": "Screenshot_20210403-175831.png",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/720x1512_Screenshot_20210403-175831.png",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x1050_Screenshot_20210403-175831.png 500w",
                    "/static/_gallery/albums/gore/720x1512_Screenshot_20210403-175831.png 720w",
                    "/static/_gallery/albums/gore/720x1512_Screenshot_20210403-175831.png 720w",
                    "/static/_gallery/albums/gore/720x1512_Screenshot_20210403-175831.png 720w"
                ],
                "width": 720
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x1050_Screenshot_20210406-005335.png",
                "faces": {},
                "height": 1512,
                "name": "Screenshot_20210406-005335.png",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/720x1512_Screenshot_20210406-005335.png",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x1050_Screenshot_20210406-005335.png 500w",
                    "/static/_gallery/albums/gore/720x1512_Screenshot_20210406-005335.png 720w",
                    "/static/_gallery/albums/gore/720x1512_Screenshot_20210406-005335.png 720w",
                    "/static/_gallery/albums/gore/720x1512_Screenshot_20210406-005335.png 720w"
                ],
                "width": 720
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x1050_Screenshot_20210425-040552.png",
                "faces": {},
                "height": 1512,
                "name": "Screenshot_20210425-040552.png",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/720x1512_Screenshot_20210425-040552.png",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x1050_Screenshot_20210425-040552.png 500w",
                    "/static/_gallery/albums/gore/720x1512_Screenshot_20210425-040552.png 720w",
                    "/static/_gallery/albums/gore/720x1512_Screenshot_20210425-040552.png 720w",
                    "/static/_gallery/albums/gore/720x1512_Screenshot_20210425-040552.png 720w"
                ],
                "width": 720
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x13_a.png",
                "faces": {},
                "height": 30,
                "name": "a.png",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1143x30_a.png",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x13_a.png 500w",
                    "/static/_gallery/albums/gore/800x20_a.png 800w",
                    "/static/_gallery/albums/gore/1024x26_a.png 1024w",
                    "/static/_gallery/albums/gore/1143x30_a.png 1143w"
                ],
                "width": 1143
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x69_g.png",
                "faces": {},
                "height": 137,
                "name": "g.png",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/990x137_g.png",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x69_g.png 500w",
                    "/static/_gallery/albums/gore/800x110_g.png 800w",
                    "/static/_gallery/albums/gore/990x137_g.png 990w",
                    "/static/_gallery/albums/gore/990x137_g.png 990w"
                ],
                "width": 990
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x222_google.png",
                "faces": {},
                "height": 310,
                "name": "google.png",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/697x310_google.png",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x222_google.png 500w",
                    "/static/_gallery/albums/gore/697x310_google.png 697w",
                    "/static/_gallery/albums/gore/697x310_google.png 697w",
                    "/static/_gallery/albums/gore/697x310_google.png 697w"
                ],
                "width": 697
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x281_looking-for.png",
                "faces": {},
                "height": 1440,
                "name": "looking-for.png",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1600x900_looking-for.png",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x281_looking-for.png 500w",
                    "/static/_gallery/albums/gore/800x450_looking-for.png 800w",
                    "/static/_gallery/albums/gore/1024x576_looking-for.png 1024w",
                    "/static/_gallery/albums/gore/1600x900_looking-for.png 1600w"
                ],
                "width": 2560
            },
            {
                "_thumb": "/static/_gallery/albums/gore/195x375_mpv-shot0004.jpg",
                "faces": {},
                "height": 375,
                "name": "mpv-shot0004.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/195x375_mpv-shot0004.jpg",
                "srcSet": [
                    "/static/_gallery/albums/gore/195x375_mpv-shot0004.jpg 195w",
                    "/static/_gallery/albums/gore/195x375_mpv-shot0004.jpg 195w",
                    "/static/_gallery/albums/gore/195x375_mpv-shot0004.jpg 195w",
                    "/static/_gallery/albums/gore/195x375_mpv-shot0004.jpg 195w"
                ],
                "width": 195
            },
            {
                "_thumb": "/static/_gallery/albums/gore/500x666_signal-2022-09-26-180235_002.jpeg",
                "faces": {},
                "height": 2048,
                "name": "signal-2022-09-26-180235_002.jpeg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/gore/1536x2048_signal-2022-09-26-180235_002.jpeg",
                "srcSet": [
                    "/static/_gallery/albums/gore/500x666_signal-2022-09-26-180235_002.jpeg 500w",
                    "/static/_gallery/albums/gore/800x1066_signal-2022-09-26-180235_002.jpeg 800w",
                    "/static/_gallery/albums/gore/1024x1365_signal-2022-09-26-180235_002.jpeg 1024w",
                    "/static/_gallery/albums/gore/1536x2048_signal-2022-09-26-180235_002.jpeg 1536w"
                ],
                "width": 1536
            }
        ],
        "src": "/static/_gallery/albums/gore/352x479_2015-12-22-095232_352x479_scrot.png"
    }
};